@import "../../utils/colorCodes.scss";

.publications-container {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: $mainBackground;
  padding: 0 3rem;
}

.publications-pageTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 2rem 0 0.5rem 0;
}

.publications-loading {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.publications-tabpanel {
  width: 100%;
  height: 100%;
}

.publications-error {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  flex-direction: column;
}

.publications-actionButton {
  border: 1px solid transparent;
  border: 2px solid $buttonBackground;
  border-radius: 5px;
  background-color: $buttonBackground;
  color: $buttonFont;
  width: 30%;
  max-width: 150px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin: 0.5rem 0;
}

.publications-actionButton:hover {
  background-color: $buttonBackgroundHover;
  color: $buttonFontHover;
  border: 2px solid $buttonFontHover;
}

@media only screen and (max-width: 600px) {
  .publications-container {
    padding: 0 1.5rem;
  }
}
