@import "../../utils/colorCodes.scss";

.userProfile-container {
  width: 100%;
  height: 90vh;
  background-color: $mainBackground;
  padding: 0 1rem;
}

.userProfile-error {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}

.userProfile-title {
  // height: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0 4rem 0;
}

.userProfile-account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userProfile-account {
  border: 1px solid #dfdfdf;
  border-left: 5px solid #03a9f4;
  background-color: white;
  width: 40%;
  padding: 1rem;
  box-shadow: 0 8px 17px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  display: grid;
  grid-template-areas: "userAttribute userInfo";
  grid-template-columns: 50% 50%;
  margin: 0.5rem 0;
}

.userProfile-userAttribute {
  grid-area: userAttribute;
}

.userProfile-userInfo {
  grid-area: userInfo;
}

@media only screen and (max-width: 600px) {
  .userProfile-account {
    width: 80%;
  }
}
