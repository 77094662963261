.loginLoading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #73c8a9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #373b44,
    #73c8a9
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #373b44,
    #73c8a9
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.loginLoading-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  color: #ffffff;
}

.loginLoading-button {
  margin: 2rem 0 0 0;
  border: 2px solid #373b44;
  background-color: #373b44;
  color: #ffffff;
  font-size: 1.2rem;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  text-decoration: unset;
}

.loginLoading-button:hover {
  border-radius: 1rem;
  border: 2px solid #373b44;
  background-color: white;
  color: #373b44;
}
