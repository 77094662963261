$headerBackground: #009688;
$headerFontColor: #ffffff;
$headerMenuFontColor: #000000;

$mainBackground: #eeeeee;
$secondaryBackground: #03a9f4;
$shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

$listItemBorder: #03a9f4;
$fontColorGrey: #838383;

$buttonBackground: #03a9f4;
$buttonFont: #ffffff;

$buttonBackgroundHover: #ffffff;
$buttonFontHover: #03a9f4;

$borderGrey: #838383;
