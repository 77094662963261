@import "../../utils/colorCodes.scss";

.publicationList-container {
  display: flex;
  flex-direction: column;
}

.publicationList-itemContainer {
  margin: 0.5rem 0;
  background-color: #ffffff;
  border-left: 5px solid $listItemBorder;
  // padding: 1rem;
  box-shadow: $shadow;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.publicationList-itemLinkContainer {
  display: flex;
  justify-content: center;
  align-self: center;
}

.publicationList-itemLink {
  color: #000000 !important;
  margin: 0 0.25rem;
  display: flex;
}

.publicationList-itemMainInfoContainer {
  display: flex;
  flex-direction: column !important;
}

.publicationList-itemTitle {
  margin: 0 0.25rem;
}

.publicationList-itemAuthors {
  color: $fontColorGrey;
  font-size: 13px;
  margin: 0 0.25rem;
}

.publicationList-itemPublicationContainer {
  display: flex;
  flex-direction: column !important;
}

.publicationList-itemPubContainer {
  display: flex;
  flex-direction: row;
}

.publicationList-itemPubName {
  margin: 0 0.25rem;
}

.publicationList-itemPubType {
  margin: 0 0.25rem;
  color: $fontColorGrey;
  font-size: 13px;
  display: flex;
  align-self: end;
}

.publicationList-itemPubDate {
  margin: 0 0.25rem;
}

.publicationList-itemPubRange {
  margin: 0 0.25rem;
  color: $fontColorGrey;
  font-size: 13px;
}

.publicationList-itemPubVolume {
  margin: 0 0.25rem;
  color: $fontColorGrey;
  font-size: 13px;
}

.publicationList-deleteContainer {
  display: flex;
  justify-content: center;
}

.publicationList-deleteButton {
  background-color: unset;
  border: none;
  color: $fontColorGrey;
  cursor: pointer;
}

.publicationList-error {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}

.publicationList-actionButton {
  border: 1px solid transparent;
  border: 2px solid $buttonBackground;
  border-radius: 5px;
  background-color: $buttonBackground;
  color: $buttonFont;
  width: 30%;
  max-width: 150px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin: 0.5rem 0;
  align-self: end;
  display: flex;
  align-items: center;
  justify-content: center;
}

.publicationList-actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.publicationList-actionButton:hover {
  background-color: $buttonBackgroundHover;
  color: $buttonFontHover;
  border: 2px solid $buttonFontHover;
}

@media only screen and (max-width: 600px) {
  .publicationList-itemLinkContainer {
    display: flex !important;
    flex-direction: column !important;
  }

  .publicationList-buttonIcon {
    width: 0.6em !important;
  }
}
